import { Link } from "gatsby"
import React, { useEffect, useRef } from "react"
import Searcher from "../search"
import { connect } from "react-redux"
import { getDevelopmentsAction } from "../../redux/developmentsDucks"
import { getTotalListFilters } from "../../redux/propertiesDucks"
import Loading from "../Loading"
import OwlCarousel from "react-owl-carousel3"

//Helpers
import {
  getLocation,
  getLastFeatured,
  getFakeAddress,
} from "../../helpers/helper.developments"
import { getCover } from "../../helpers/helper.rendering"
import { graphql, useStaticQuery } from "gatsby"
import {
  getActionByPage,
  getActionByType,
  getCodeFromType,
  getCodes,
  getSectionFromCode,
} from "../../helpers/helper.actions"
import { informCustomEvent } from "../../helpers/helper.analytics"
import { useGetPushQuery } from "../../redux/mediacore/push"
import { useGetDevelopmentsQuery } from "../../redux/middlewareTokkoApi/developments"

const Home_Main = ({ developments, api_key, dispatch, actions }) => {
  const isBrowser = typeof window !== "undefined"

  const slider = useRef()

  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        env {
          CLIENTID
        }
        keys {
          tokko
        }
        slogan
        main_image
        proFeatures {
          locationsAdv
        }
      }
    }
  `)

  const { template_version, proFeatures } = realEstate
  const { locationsAdv } = proFeatures

  const { env } = realEstate
  const { data: allPushData, isLoading: loading } = useGetPushQuery(
    env.CLIENTID
  )

  // const slider_action = getActionByPage(getActionByType(actions,'Slider de Imagenes Encabezado'),'Inicio')
  // const image_action = getActionByPage(getActionByType(actions,'Imagen Encabezado'),'Inicio')
  // const video_action = getActionByPage(getActionByType(actions,'Video Encabezado'),'Inicio')
  const slider_action = getActionByPage(
    getActionByType(
      allPushData,
      getCodeFromType("Slider de Imagenes Encabezado")
    ),
    "INI"
  )
  const image_action = getActionByPage(
    getActionByType(allPushData, getCodeFromType("Imagen Encabezado")),
    "INI"
  )
  const video_action = getActionByPage(
    getActionByType(allPushData, getCodeFromType("Video Encabezado")),
    "INI"
  )

  const { data: allDevelopmentsData } = useGetDevelopmentsQuery(
    realEstate.keys.tokko
  )

  const makeLink = development => {
    if (development) {
      return (
        "/emprendimientos/" +
        getFakeAddress(development).replaceAll(" ", "-") +
        "/" +
        development.id
      )
    }
  }

  const getSize = () => isBrowser && (screen.width < 992 ? "mobile" : "desktop")

  const goTo = () => {
    if (video_action[0].url_destiny) {
      window.open(
        video_action[0].url_destiny + video_action[0].text_utm,
        "_blank",
        "noopener,noreferrer"
      )
      informCustomEvent(
        "PUSH_" +
          getCodes(video_action[0].type, video_action[0].section) +
          "_" +
          video_action[0].name
      )
    }
  }

  useEffect(() => {
    if (video_action.length > 0) {
      informCustomEvent(
        "SHOW_" +
          getCodes(video_action[0]?.type, video_action[0]?.section) +
          "_" +
          video_action[0]?.name
      )
    }
    if (image_action.length > 0) {
      informCustomEvent(
        "SHOW_" +
          getCodes(image_action[0]?.type, image_action[0]?.section) +
          "_" +
          image_action[0]?.name
      )
    }
    if (slider_action.length > 0) {
      informCustomEvent(
        "SHOW_" +
          getCodes(slider_action[0]?.type, slider_action[0]?.section) +
          "_" +
          slider_action[0]?.name
      )
    }
  }, [])

  return !loading ? (
    slider_action.length > 0 || video_action.length > 0 ? ( //Caso Actions video/slider
      <>
        {slider_action.length > 0 && (
          <>
            <div id="section-galery-home">
              <div id="dev-secuencial">
                <div className="slider" id="dev-gallery">
                  <OwlCarousel
                    items={1}
                    ref={slider}
                    margin={0}
                    nav={false}
                    dots={true}
                    loop={true}
                    rewind={true}
                    autoplay={true}
                    autoplayTimeout={
                      parseInt(slider_action[0]?.transition_time) * 1000
                    }
                    id="prop-gallery"
                  >
                    {slider_action[0].files.map((file, index) => (
                      <div
                        className={
                          "item banner-main banner-ficha emprendimiento " +
                          (locationsAdv && " custom-height")
                        }
                        key={index}
                      >
                        <img
                          src={file.file}
                          className="object-cover object-center cursor-pointer"
                          alt={realEstate?.name}
                        />
                        <div className="info-banner position-absolute">
                          <div className="info row align-items-center text-center justify-content-center">
                            <div className="col-8 col-lg-12">
                              <a
                                target={"_blank"}
                                onClick={() =>
                                  informCustomEvent(
                                    "PUSH_" +
                                      getCodes(
                                        slider_action[0].type,
                                        slider_action[0].section
                                      ) +
                                      "_" +
                                      slider_action[0].name
                                  )
                                }
                                href={
                                  file?.url_destiny + slider_action[0].text_utm
                                }
                                className={
                                  "link-gallery " +
                                  (!file.url_destiny && "d-none")
                                }
                              ></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                  <div className="arrows-owl">
                    <div
                      className="arrow"
                      onClick={() => slider.current.prev()}
                    >
                      <div className="next">
                        <i className="icon-arrow"> </i>
                      </div>
                    </div>
                    <div
                      className="arrow "
                      onClick={() => slider.current.next()}
                    >
                      <div className="next">
                        <i className="icon-arrow"> </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {}
            </div>
          </>
        )}
        {video_action.length > 0 && (
          <>
            <div
              id="home-main"
              className={
                (locationsAdv && "custom-height") +
                " banner-main isVideo " +
                (video_action[0]?.url_destiny === ""
                  ? "cursor-default"
                  : "cursor-pointer")
              }
            >
              {!(
                getSize() === "mobile" &&
                !video_action[0]?.files?.find(
                  video => video.device === "mobile"
                )
              ) && (
                <video onClick={() => goTo()} loop autoPlay muted>
                  <source
                    src={
                      video_action[0]?.files?.filter(
                        video => video.device === getSize()
                      )?.[0].file ?? ""
                    }
                    type="video/mp4"
                  />
                </video>
              )}
              {}
            </div>
          </>
        )}
      </>
    ) : (
      // Casos sin actiones (Con emprendimientos o sin).
      <>
        {allDevelopmentsData?.objects?.length &&
        !image_action &&
        getLastFeatured(allDevelopmentsData?.objects) ? (
          <div
            id="home-main"
            className={"banner-main " + (locationsAdv && "custom-height")}
            style={{
              backgroundImage:
                "url(" +
                (allDevelopmentsData
                  ? getCover(
                      getLastFeatured(allDevelopmentsData?.objects).photos
                    )?.original
                  : realEstate?.main_image) +
                ")",
            }}
          >
            <div className="item d-flex justify-content-center text-center align-items-center">
              <div className="opacity"></div>
              <div className="container" style={{ zIndex: "1" }}>
                <div className="row align-items-center text-center justify-content-center">
                  <div className="col-12 pb-5">
                    <h1>
                      {allDevelopmentsData &&
                        getLastFeatured(allDevelopmentsData?.objects).name}
                    </h1>
                    <p>
                      {allDevelopmentsData &&
                        getLocation(
                          getLastFeatured(allDevelopmentsData?.objects),
                          true
                        )[0]}
                      <br />
                      {allDevelopmentsData &&
                        getLocation(
                          getLastFeatured(allDevelopmentsData?.objects),
                          true
                        )[1]}
                    </p>
                    <a
                      href={
                        allDevelopmentsData &&
                        makeLink(getLastFeatured(allDevelopmentsData?.objects))
                      }
                      target="_blank"
                      className="btn btn-blue mb-5"
                    >
                      DESCUBRILO
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {}
          </div>
        ) : (
          <div
            id="home-main"
            onClick={() =>
              informCustomEvent(
                "PUSH_" +
                  getCodes(image_action[0]?.type, image_action[0]?.section) +
                  "_" +
                  image_action[0]?.name
              ) +
              console.log(
                "PUSH_" +
                  getCodes(image_action[0]?.type, image_action[0]?.section) +
                  "_" +
                  image_action[0]?.name
              ) +
              image_action[0]?.url_destiny
                ? window.open(
                    image_action[0].url_destiny + image_action[0]?.text_utm,
                    "_blank",
                    "noopener,noreferrer"
                  )
                : ""
            }
            class={
              "banner-main isHome " +
              (image_action.length === 0 || image_action[0]?.url_destiny === ""
                ? " cursor-default "
                : "") +
              (locationsAdv && " custom-height ") +
              (image_action.length > 0 ? " isImageFeatured " : "")
            }
            style={{
              backgroundImage:
                "url(" +
                (image_action.length > 0
                  ? image_action[0]?.files[0].file
                  : realEstate?.main_image) +
                ")",
            }}
          >
            {image_action.length === 0 && (
              <div
                class={
                  "item d-flex justify-content-center text-center align-items-center " +
                  (!locationsAdv && "lite-version")
                }
              >
                <div class="opacity"></div>
                <div class="container" style={{ zIndex: "1" }}>
                  <div class="row align-items-center text-center justify-content-center">
                    <div class="col-12 wpb-5 pt-5 pt-lg-0 mt-5 mt-lg-0 mb-lg-0 px-5">
                      <h1 className={locationsAdv ? "mb-custom" : ""}>
                        {realEstate?.slogan.split("\\n").map((text, index) => (
                          <>
                            {text}
                            {index !==
                              realEstate?.slogan.split("\n").length && <br />}
                          </>
                        ))}
                      </h1>
                    </div>
                  </div>
                  <div className="d-block d-lg-none">{}</div>
                </div>
              </div>
            )}
            <div className="d-none d-lg-block">{}</div>
          </div>
        )}
      </>
    )
  ) : (
    <div id="home-main" class={"banner-main isHome skeleton"}>
      {!locationsAdv && <Loading />}
      {}
    </div>
  )
}

export default connect(
  state => ({
    developments: state.developments.developments,
    actions: state.actions.actions,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko,
  }),
  null
)(Home_Main)
